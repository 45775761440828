<script setup>

import {reactive, ref} from "vue"
import auth from "../auth"
import statics from "../static"
import {useRouter} from "vue-router"
import {Snackbar} from "@varlet/ui";

const router = useRouter()

const loading = ref(false)
const form = ref(null)
const formData = reactive({
  first_name: null,
  last_name: null,
  organization: null,
  email: null,
  password: null,
  password_confirmation: null,
})

const submit = () => {
  loading.value = true

  auth.register(formData)
      .then(data => {
        loading.value = false
        router.push({
          name: "Verification",
          params: {
            email: formData.email
          }
        })
      })
      .catch(error => {
        loading.value = false

        if (error.response.status === 422) {
          // let errorData = error.response.data.errors
          // for (const key of Object.keys(errorData)) {
          //   errors[key] = errorData[key][0]
          // }
          console.log(error)
        } else {
          Snackbar['error'](statics.server_error)
        }
      })
}

</script>

<template>
  <var-skeleton :rows="2" :loading="loading">
    <var-form ref="form">
      <var-input
          placeholder="Please input first name"
          :rules="[v => !!v || 'The first name cannot be empty']"
          v-model="formData.first_name"
          class="mb-3"
      />
      <var-input
          placeholder="Please input last name"
          :rules="[v => !!v || 'The last name cannot be empty']"
          v-model="formData.last_name"
          class="mb-3"
      />
      <var-input
          placeholder="Please input organization name"
          :rules="[v => !!v || 'The organization name cannot be empty']"
          v-model="formData.organization"
          class="mb-3"
      />
      <var-input
          placeholder="Please input email"
          :rules="[v => !!v || 'The email cannot be empty']"
          v-model="formData.email"
          class="mb-3"
      />
      <var-input
          type="password"
          placeholder="Please input password"
          :rules="[v => !!v || 'The password cannot be empty']"
          v-model="formData.password"
          class="mb-3"
      />
      <var-input
          type="password"
          placeholder="Please input password confirmation"
          :rules="[v => !!v || 'The password confirmation cannot be empty']"
          v-model="formData.password_confirmation"
          class="mb-3"
      />
    </var-form>

    <var-button @click="submit" type="primary" :loading="loading">Register</var-button>
  </var-skeleton>
</template>

<style>

</style>